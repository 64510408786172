import React from 'react';

const TitleDecoration = () => {
    return (
        <div>
            <span className="decor-1 decor-pri" />
            <span className="decor-2 decor-pri" />
            <span className="decor-1 decor-pri" />
        </div>
    )
}

export default TitleDecoration;