import React, { useState } from "react";
import PageTemplate from "../components/page-template";
import Hero from "../components/hero";
import ServicesCards from "../components/services-cards";
import ContactForm from "../components/contact-form";
import ScrollDownIcon from "../components/scrolldown-icon";
import CaseStudies from "../components/CaseStudies";
import { Link } from "gatsby";
import {
  FaPaperPlane,
  FaSlack,
  FaFacebookMessenger,
  FaMobileAlt,
  FaEnvelope
} from "react-icons/Fa";

import axios from "axios";

// const IndexPage = () => {
//   return (
//     <PageTemplate
//       title={
//         "IT Consultancy in the north east of england, web development and hosted solutions available"
//       }
//       metaName={"description"}
//       metaDescription={"Leading UK IT Consultants"}
//     >
//       <Hero
//         size="medium"
//         color={"gradient"}
//         content={
//           <div style={{ textAlign: "center" }}>
//             <div className="columns">
//               <div className="column">
//                 <div
//                   className="index-heading-container"
//                   style={{ marginBottom: 15 }}
//                 >
//                   <h1 className="title primary-color index-title">
//                     {/* Digital Agency based in the North East, UK. */}
//                     We are a <strong>Website</strong> development agency located
//                     in the <strong>North East</strong> of England 🇬🇧
//                   </h1>
//                   <div className="button-group is-full-width">
//                     <a
//                       href="/about"
//                       style={{ marginRight: 5, width: "48%" }}
//                       className="button is-primary is-half-width"
//                     >
//                       About
//                     </a>

//                     <a
//                       href="#contact"
//                       style={{ marginRight: 5, width: `48%` }}
//                       className="button is-secondary"
//                     >
//                       Contact us
//                     </a>
//                   </div>
//                 </div>
//               </div>
//               <div className="column"></div>
//             </div>

//             <div style={{ position: "absolute", right: 0 }}>
//               <ScrollDownIcon />
//             </div>
//           </div>
//         }
//       />
//       <section className="section">
//         <div className="container">
//           <h2>
//             <strong>"</strong>we offer a fully comprehensive web consultancy
//             service ranging from IT advice and planning to{" "}
//             <Link to="/website">website</Link> and <Link to="/apps">app</Link>{" "}
//             development<strong>"</strong>
//           </h2>
//         </div>
//       </section>

//       <ServicesCards />

//       <CaseStudies />

//       <ContactForm />
//     </PageTemplate>
//   );
// };

const IndexPage = () => {
  const [value, updateValue] = useState("");
  const [subscribed, updateSubscribed] = useState(false);

  const subscribe = (e) => {
    e.preventDefault();
    if (validateEmail(value)) {
      axios
        .post("https://us18.api.mailchimp.com/3.0/lists/d7a0c25ce7/members/", {
          email_address: `${value}`,
          status: "subscribed",
          merge_fields: {
            FIRSTNAME: "",
            LASTNAME: ""
          }
        })
        .then(d => {
          console.log("THEN D");
          console.log(d);
        })
        .catch(e => {
          console.log("error");
          console.log(e);
        });
    }
  };

  const validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  return (
    <Hero
      size="medium"
      color={"gradient"}
      content={
        <section style={{ minHeight: "100vh" }} className="section">
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="column is-10 is-offset-1">
                  <div style={{ textAlign: "center", color: "white" }}>
                    <h1
                      style={{ color: "white", fontSize: 44 }}
                      className="brand-font title"
                    >
                      innovate
                    </h1>

                    <div className="coming-soon-card">
                      <div
                        style={{
                          width: 300,
                          height: "100%",
                          marginLeft: "calc(50% - 150px)"
                        }}
                      >
                        <p style={{ fontSize: 17 }}>
                          Our website is currently under construction but is due
                          to launch pretty soon.
                        </p>
                      </div>

                      <form style={{ marginTop: 30 }}>
                        {subscribed ? (
                          <div>
                            <label>Thank you!</label>
                          </div>
                        ) : (
                          <div>
                            <label>Subscribe to our alerts</label>
                            <div className="subscribe-input">
                              <input
                                name="email"
                                autoComplete={"email"}
                                placeholder={"email"}
                                required={true}
                                type="email"
                                value={value}
                                onChange={(e) => updateValue(e.target.value)}
                              />
                              <button
                                onClick={e => subscribe(e)}
                                className="subscribe-btn"
                              >
                                <FaPaperPlane color={"#fff"} size={20} />
                              </button>
                            </div>
                          </div>
                        )}
                      </form>

                      <div>
                        <div className="contact-icon">
                          <a href="https://join.slack.com/t/innovateweb/shared_invite/enQtNzcwOTI0MTI2MjI3LWM4ZTJkNjFkNDZhNTkxYjRjNWFlODRlZWFhNmVmNDE2YjBlMGE1NzI1MTM2NmRkMzgxOGRmMTllMWZjODUyYzQ">
                            <FaSlack size={30} />
                          </a>
                        </div>
                        <div className="contact-icon">
                          <a href="m.me/InnovateWebSolutions">
                            <FaFacebookMessenger size={30} />
                          </a>
                        </div>
                        <div className="contact-icon">
                          <a href="tel:+447378635404">
                            <FaMobileAlt size={30} />
                          </a>
                        </div>
                        <div className="contact-icon">
                          <a href="mailto:hello@innovateweb.co.uk">
                            <FaEnvelope size={30} />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
    />
  );
};

export default IndexPage; //IndexPage;
