import React, { useState } from "react";
import { FaAt, FaUser, FaExclamationTriangle, FaRegCheckCircle, FaRegPaperPlane } from "react-icons/fa";
import TitleDecoration from "./title-decoration";


const ContactForm = () => {
  const [name, updateName] = useState("");
  const [nameValid, updateNameValid] = useState(null);
  const [email, updateEmail] = useState("");
  const [emailValid, updateEmailValid ] = useState(null);
  // const [emailError, updateEmailError] = useState(true)
  const [reasonForContact, updateReasonForContact] = useState(
    "I'd like a consultation"
  );
  const [budget, updateBudget] = useState("£250 - £500");
  const [message, updateMessage] = useState("");
  const [messageValud, updateMessageValid ] = useState(null)
  const [subscribe, updateSubscribe] = useState("");
  

  const validate = (type, value) => {
    switch(type){
      case 'name':
         updateNameValid(value.length >= 2);
         break;
      case 'email':
          updateEmailValid(vE(value));
        default:
          break;
    }
  }

  const vE = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLocaleLowerCase());
  }

  return (
    <section id="contact" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <h2 className="title">Lets Chat 👋</h2>
            <TitleDecoration />
            <p>We can provide you with bespoke IT Solutions that will empower your business. </p>
            <p style={{marginTop: 20}}>Get in touch today for your free, no obligation consultation session.</p>
            <ul style={{marginTop: 20, marginBottom: 20}}>
                <li><FaRegCheckCircle color="green"/> Comprehensive list of services</li>
                <li><FaRegCheckCircle color="green"/> Automate your business tasks</li>
                <li><FaRegCheckCircle color="green"/> Innovate your process</li>
                <li><FaRegCheckCircle color="green"/> Thrive with technology</li>
            </ul>
          </div>
          <div className="column">
            <form className="contactForm">
              <div className="field">
                <label className="label">Full Name</label>
                <div className="control">
                  <input
                    className={nameValid == null ? `input` : nameValid ? `input is-success` : `input is-danger`}
                    type="text"
                    placeholder="John Smith"
                    onChange={e => {
                      validate('name', e.target.value)
                      updateName(e.target.value)
                    }}
                  />
                </div>
              </div>

              <div className="field">
                <label className="label">Email</label>
                <div className="control has-icons-left has-icons-right">
                  <input
                    className={emailValid == null ? "input" : !emailValid ? "input is-danger" : "input"}
                    type="email"
                    placeholder="Email input"
                    value={email}
                    onChange={(e) => {
                      validate('email', e.target.value)
                      updateEmail(e.target.value)
                    }}
                  />
                  <span className="icon is-small is-left">
                    <FaAt />
                  </span>
                  <span className="icon is-small is-right">
                    {emailValid == false ? <FaExclamationTriangle color="red" /> : null}
                  </span>
                </div>
                <p className="help is-danger">{emailValid == false ? `This email is invalid` : null}</p>
              </div>

              <div className="field">
                <label className="label">Reason for contact</label>
                <div className="control">
                  <div className="select is-full-width">
                    <select
                      onChange={e => updateReasonForContact(e.target.value)}
                    >
                      <option>I'd like a consultation</option>
                      <option>I'd like to discuss a project</option>
                      <option>I just wanted to say hello</option>
                    </select>
                  </div>
                </div>
              </div>

              {reasonForContact == "I'd like to discuss a project" ? (
                <div className="field">
                  <label className="label">My Budget is</label>
                  <div className="control">
                    <div className="select">
                      <select>
                        <option>£250 - £500</option>
                        <option>£500 - £1000</option>
                        <option>£1000 - £5000</option>
                        <option>£5000 - £10,000</option>
                        <option>£10,000 - £20,000</option>
                        <option>£20,000 +</option>
                      </select>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="field">
                <label className="label">Message</label>
                <div className="control">
                  <textarea
                    className="textarea"
                    placeholder="Please enter the details of your enquiry"
                  ></textarea>
                </div>
              </div>

              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" />I agree to the{" "}
                    <a href="#">terms and conditions</a>
                  </label>
                </div>
              </div>

              <div className="field is-grouped">
                <button
                  style={{ width: "100%" }}
                  className="button is-primary is-full-width"
                >
                  Submit <FaRegPaperPlane style={{marginLeft: 5}} />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
