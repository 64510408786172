import React, { useState } from "react";

import ReactLogo from "../images/tech-logos/react.png";
import NodeLogo from "../images/tech-logos/node.png";
import StripeLogo from "../images/tech-logos/stripe.png";
import MongoDB from "../images/tech-logos/mongodb.png";
import MerkyMiniesDesktop from "../images/case-studies/merky-minies-desktop.png";
import MerkyMiniesMobile from "../images/case-studies/merky-minies-mobile.png";
import Html5ILogo from "../images/tech-logos/html5.png";
import SassLogo from "../images/tech-logos/saas.png";
import BootstrapLogo from "../images/tech-logos/bootstrap.png";
import ModernDesktop from "../images/case-studies/modern-rusic-desktop.png";
import ModernMobile from "../images/case-studies/modern-rustic-mobile.png";
import Fade from "react-reveal/Fade";

const staticData = [
  {
    id: 1,
    name: "Merky Minies",
    logo: null,
    screenShotDesktop: MerkyMiniesDesktop,
    screenShotMobile: MerkyMiniesMobile,
    solution: "eCommerce / Website",
    description:
      "Merky Minies is an unique, urban childrens clothing brand. Our work has allowed Merky Minies to blah blah blah....",
    url: "https://merkyminies.co.uk",
    tech: [
      {
        name: "React",
        description: "Server side rendering for blazing fast, SEO pages.",
        icon: ReactLogo
      },
      {
        name: "Node",
        description: "REST API built with express.js",
        icon: NodeLogo
      },
      {
        name: "Stripe",
        description: "Stripe payment gateway",
        icon: StripeLogo
      },
      {
        name: "Mongo",
        description: "MongoDB Database storage",
        icon: MongoDB
      }
    ]
  },
  {
    id: 2,
    name: "Modern Rustic Workshop",
    logo: null,
    screenShotDesktop: ModernDesktop,
    screenShotMobile: ModernMobile,
    solution: "Portfolio / Website",
    description:
      "Modern rustic workshop needed something that was SEO friendly and blazinly quick.",
    url: "https://merkyminies.co.uk",
    tech: [
      {
        name: "HTML5",
        description: "Server side rendering for blazing fast, SEO pages.",
        icon: Html5ILogo
      },
      {
        name: "Bootstrap",
        description: "REST API built with express.js",
        icon: BootstrapLogo
      },
      {
        name: "Saas",
        description: "Stripe payment gateway",
        icon: SassLogo
      }
    ]
  }
];

const CaseStudies = () => {
  const [data, updateData] = useState(staticData);
  return (
    <div>
      {data.map(d => {
        return d.id == 1 ? (
          <section className="section secondary-background">
            <div className="container">
              <h3 className="title white">Recent Projects 🖥️</h3>

              <div style={{ marginTop: 50 }} className="columns">
                <div className="column">
                  <Fade left>
                    <div className="browser is-hidden-touch">
                      <div className="top-bar">
                        <div className="dot-1" />
                        <div className="dot-2" />
                        <div className="dot-3" />
                      </div>

                      <img src={d.screenShotDesktop} />
                    </div>
                  </Fade>

                  <Fade left>
                    {" "}
                    <div className="iphone is-hidden-desktop">
                      <div className="speaker">
                        <div className="bar" />
                        <div className="camera" />
                      </div>

                      <img src={d.screenShotMobile} />
                      <div className="home" />
                    </div>
                  </Fade>
                </div>
                <div className="column">
                  <Fade right>
                    <div className="card case-study">
                      <div className="card-content">
                        <h3>{d.name}</h3>
                        <h4>[{d.solution}]</h4>
                        <p>{d.description}</p>
                        <a
                          href={d.url}
                          className="button is-rounded is-primary"
                        >
                          VISIT
                        </a>
                        <div className="card-footer">
                          <div
                            style={{
                              paddingTop: 20,
                              marginRight: 5,
                              display: "block"
                            }}
                          >
                            Tech Stack:
                          </div>
                          <div>
                            {d.tech.map(t => (
                              <div className="tech-icons">
                                <img src={t.icon} alt={t.name} />
                                <div className="tooltip">{t.description}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="section primary-background">
            <div className="container">
              <div style={{ marginTop: 50 }} className="columns">
                <div className="column">
                  <Fade left>
                    <div className="card case-study">
                      <div className="card-content">
                        <h3>{d.name}</h3>
                        <h4>[{d.solution}]</h4>
                        <p>{d.description}</p>
                        <a href={d.url} className="button is-rounded is-info">
                          VISIT
                        </a>
                        <div className="card-footer">
                          <div
                            style={{
                              paddingTop: 20,
                              marginRight: 5,
                              display: "block"
                            }}
                          >
                            Tech Stack:
                          </div>
                          <div>
                            {d.tech.map(t => (
                              <div className="tech-icons">
                                <img src={t.icon} alt={t.name} />
                                <div className="tooltip">{t.description}</div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </div>
                <div className="column">
                  <Fade right>
                    <div className="browser is-hidden-touch">
                      <div className="top-bar">
                        <div className="dot-1" />
                        <div className="dot-2" />
                        <div className="dot-3" />
                      </div>

                      <img src={d.screenShotDesktop} />
                    </div>
                  </Fade>

                  <Fade right>
                    <div className="iphone is-hidden-desktop">
                      <div className="speaker">
                        <div className="bar" />
                        <div className="camera" />
                      </div>
                      <img src={d.screenShotMobile} />
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </section>
        );
      })}
    </div>
  );
};

export default CaseStudies;
