import React, { useState } from "react";
import TitleDecoration from "./title-decoration";
import Consulting from "../images/consulting.jpg";
import WebDevelopment from "../images/web-development.jpg";
import Seo from "../images/SEO.jpg";
import ProjectManagement from "../images/project-management.jpg";
import ManagedServices from "../images/managed-services.jpg";
import Hosting from "../images/Hosting.jpg";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";
const serviceList = [
  {
    id: 1,
    name: "Websites",
    image: Consulting,
    url: "/websites",
    description: "[Corperate Sites, E-Commerce, Landing Pages]"
  },
  {
    id: 2,
    name: "Apps",
    image: WebDevelopment,
    url: "/apps",
    description: "[Corperate Sites, E-Commerce, Landing Pages]"
  },
  {
    id: 3,
    name: "Digital Marketing",
    image: Seo,
    url: "digital-marketing",
    description: "[Corperate Sites, E-Commerce, Landing Pages]"
  }
  // {
  //   id: 4,
  //   name: "Project Management",
  //   image: ProjectManagement
  // },
  // {
  //   id: 5,
  //   name: "Managed Services",
  //   image: ManagedServices
  // },
  // {
  //   id: 6,
  //   name: "Domains & Hosting",
  //   image: Hosting
  // }
];

const ServicesCards = () => {
  const [services, updateServices] = useState(serviceList);
  const [currentPage, updateCurrentPage] = useState(1);
  const [servicesPerPage, updateServicesPerPage] = useState(3);

  const indexOfLastItem = currentPage * servicesPerPage;
  const indexOfFirstItem = indexOfLastItem - servicesPerPage;
  const currentItems = services.slice(indexOfFirstItem, indexOfLastItem);
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(services.length / servicesPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <section className="section" style={{ background: "#e3e3e3" }}>
      <div className="container">
        <h2 style={{ marginBottom: 0 }} className="title">
          Our Services ⚙️
        </h2>
        <TitleDecoration />
        <div className="columns not-multiline">
          {currentItems.map((i, index) => {
            return (
              <div key={i.id} className="column is-one-mobile">
                <Link to={i.url}>
                  <Fade delay={Math.ceil(index * 100)}>
                    <div className="card service-card">
                      <div className="card-image">
                        <img src={i.image} alt={i.name} />
                      </div>

                      <div className="card-content">
                        <h3>{i.name}</h3>
                        <p className="service-description">{i.description}</p>
                      </div>
                    </div>
                  </Fade>
                </Link>
              </div>
            );
          })}
        </div>
        <div style={{ textAlign: "center" }}>
          {pageNumbers.map(i => (
            <div
              className={
                i == currentPage ? "page-dots page-dots--active" : "page-dots"
              }
              onClick={() => updateCurrentPage(i)}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesCards;
